import React from "react";
import PropTypes from "prop-types";
import navigate from "lib/navigate";
//import moment from 'moment';

import {
  arrangeData,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";

class QuestionnaireDisplayComp extends View {
  constructor(props) {
    super(props);
    this.state = {
      dbID: "",
    };
  }

  @autobind
  apply(e) {
    e.preventDefault();
    var url = "/questionnaireDetail?id=" + e.target.dataset.sn;
    //console.log(url);
    //console.log(e.target.dataset.sn);
    navigate(url);
  }

  render() {
    let {
      data,
      serialNo,
      handleClick,
    } = this.props;

    const arrangedData = arrangeData(data);

    //var now = new Date();
    //var today = now.getFullYear()+""+(now.getMonth()<10?"0":"")+(now.getMonth()+1)+""+now.getDate();
    //console.log("today="+today);

    let onlinedate = arrangedData.onlinedate
      ? arrangedData.onlinedate.substring(0, 4) +
        "/" +
        arrangedData.onlinedate.substring(4, 6) +
        "/" +
        arrangedData.onlinedate.substring(6, 8)
      : "";
    let offlinedate = arrangedData.offlinedate
      ? arrangedData.offlinedate.substring(0, 4) +
        "/" +
        arrangedData.offlinedate.substring(4, 6) +
        "/" +
        arrangedData.offlinedate.substring(6, 8)
      : "不限";

    return (
      <>
        <tr>
          <td data-title="編號">{serialNo}</td>
          <td data-title="問卷標題">{arrangedData.subject}</td>
          <td data-title="資源名稱" className="link">
            <a
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                handleClick({ id: arrangedData.dbid });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick({ id: arrangedData.dbid });
                }
              }}>
              {arrangedData.ebname}
            </a>
          </td>
          <td data-title="調查起迄時間" className="nowrap">
            {onlinedate}~{offlinedate}
          </td>
          <td className="nowrap">
            {arrangedData.isFilled && arrangedData.isFilled === "1" && "已填寫"}
            {arrangedData.isFilled && arrangedData.isFilled === "0" && (
              <a
                href="#"
                tabIndex="0"
                data-sn={arrangedData.sn}
                className="btn-primary"
                onClick={this.apply.bind(this)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.apply(e);
                  }
                }}>
                填寫
              </a>
            )}
          </td>
        </tr>
      </>
    );
  }
}

QuestionnaireDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

QuestionnaireDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default QuestionnaireDisplayComp;
